
export const ENVIRONMENT_PREFIX = "dev.";
export const API_HOST = "https://api.dev.admin.nickeldxm.com";
export const API_NAME = "dev-nickel-admin";
export const API_CONFIG = { endpoints: [{ name: API_NAME, endpoint: API_HOST }] };

export const COGNITO_CONFIG = {
  identityPoolId: "us-east-1:75c5bff6-85b0-4da5-8643-45457e931de0",
  region: "us-east-1",
  userPoolWebClientId: "3645skm0up68tellal9ge01md",
  userPoolId: "us-east-1_IA0y6qdns"
};